<template>
  <div>
    <span 
      v-if="!outputStatus.configured" 
      class="text--disabled"
    >
      <v-tooltip 
        bottom 
        max-width="600px"
        v-if="outputStatus.enabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon 
            v-bind="attrs"
            v-on="on"
            small
            color="error"
          >
            warning
          </v-icon>
        </template>
        <span style="line-height: 1.5em">
          This output is enabled, but is not fully configured...
        </span>
      </v-tooltip>
      Not Configured
    </span>
    <span 
      v-else-if="outputStatus.configured && outputStatus.status_message" 
      :class="(outputStatus.enabled) ? 'primary--text' : 'text--disabled'"
    >
      <v-tooltip 
        bottom 
        max-width="600px"
        v-if="outputStatus.enabled && !outputStatus.streaming"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon 
            v-bind="attrs"
            v-on="on"
            small
            color="error"
          >
            warning
          </v-icon>
        </template>
        <span style="line-height: 1.5em">
          This output is enabled, but is not currently streaming...
        </span>
      </v-tooltip>
      {{ outputStatus.status_message }}
    </span>
    
    <!-- legacy -->
    <span 
      v-else-if="outputStatus.configured && outputStatus.enabled && outputStatus.streaming" 
      class="primary--text"
    >
      Enabled
    </span>
    <span 
      v-else-if="outputStatus.configured && outputStatus.enabled && !outputStatus.streaming" 
      class="primary--text"
    >
      Not Streaming
    </span>
  </div>
</template>

<script>
  
  export default {
    name: 'OutputStatusLabel',
    props:['output'],
    computed: {
      outputStatus() {
        return this.$helpers.outputStatus(this.output)
      }
    }
  }
</script>