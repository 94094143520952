<template>
  <div>
    <v-row v-if="!isPreviewSupported" class="no-gutters">
      <v-col cols="12">
        <v-alert
          type="info"
          text
          icon="mdi-lock-outline"
          class="mt-3"
        >
          Input preview requires cloud agent version {{ inputPreviewSupportedVersion.original }}.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-else-if="!device.isOnline()" class="no-gutters">
      <v-col cols="12">
        <v-alert
          type="info"
          text
          class="mt-3"
        >
          Input preview unavailable while device is offline.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-else-if="error" class="no-gutters">
      <v-col cols="12">
        <v-alert
          type="error"
          text
          class="mt-3"
        >
          {{ errorText }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-else-if="!data" class="no-gutters">
      <v-progress-linear
        class="ma-5"
        color="primary"
        indeterminate
        buffer-value="0"
      ></v-progress-linear>
    </v-row>
    <v-row v-else class="no-gutters" style="position: relative">
      <img v-if="thumbnail" v-bind:src="'data:image/jpeg;base64,' + thumbnail" class="mr-6" style="max-width: 45%; box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1);"/>
      <div v-if="audio_levels" style="min-width: 45%;">
        <h5>Audio Levels</h5>
        <div v-for="channel in audio_levels" v-bind:key="channel.channel_num">
          <v-progress-linear
            height="2"
            color="red"
            class="my-1"
            :value="getAudioLevel(channel.channel_num)"
          />
        </div>
      </div>
      <div v-if="!hasFocus" style="position: absolute; text-shadow: 1px 1px 2px black;" class="caption white--text pa-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="white"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-pause
            </v-icon>
          </template>
          <span>Preview may be paused if the window does not have focus.</span>
        </v-tooltip>
      </div>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'InputPreview',
    
    props: ['device_guid', 'input_id'],
    
    data() {
      return {
        inputPreviewSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.3.0'),
        
        error: false,
        errorText: '',
        
        subscription: false,
        
        data: false,
        
        audio_levels: false,
        thumbnail: false,
        
        requestInterval: 10000,
        requestTimer: false,
        
        hasFocus: true
      }
    },
    
    computed: {
      previewTopic() {
        return 'dt/videon_cloud/device/' + this.device_guid + '/input_preview'
      },
      pubsub() {
        return this.$Amplify.PubSub
      },
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      isPreviewSupported() {
        if (this.$helpers.versionCheck(this.inputPreviewSupportedVersion, this.device.cloud_version)) {
          return true
        }
        return false
      },
    },
    
    mounted() {
      this.subscribeToTopic()
      this.requestPreview(true)
    },
    
    beforeDestroy() {
      console.log('InputPreview ' + this.device_guid + ' unsubscribe ' + this.previewTopic)
      this.subscription.unsubscribe()
    },
    
    methods: {
      subscribeToTopic() {
        if (!this.device_guid) {
          return
        }
        
        if (!this.subscription) {
          console.log('InputPreview ' + this.device_guid + ' subscribeToTopic ' + this.previewTopic)
          
          this.subscription = this.pubsub.subscribe(this.previewTopic).subscribe({
            next: (data) => { 
              if (data.value) {
                this.data = data.value
                
                // filter on input_id
                if (!this.input_id || (this.data.input_id == this.input_id)) {
                  if (this.data.audio_levels) {
                    this.audio_levels = this.data.audio_levels
                  }
                  if (this.data.thumbnail) {
                    this.thumbnail = this.data.thumbnail
                  }
                }
                
                // reset error
                this.error = false
                this.errorText = ''
              }
            },
            error: (error) => {
              console.log('InputPreview ' + this.device_guid + ' subscribeToTopic Error:', error)
              this.error = true
              this.errorText = this.$helpers.parseError(error)
            },
            complete: () => {
              console.log('InputPreview ' + this.device_guid + ' subscribeToTopic complete')
            }
          })
        }
        
      },
      
      async requestPreview(firstRun = false) {
        this.hasFocus = document.hasFocus()
        
        if (!firstRun && !this.data && document.hasFocus()) {
          this.error = true
          this.errorText = 'Preview data not received within ' + (this.requestInterval / 1000) + ' seconds.  Please check thumbnail output settings and verify they are compatible with input preview.'
        }
        
        let requestSuccess = await this.device.requestInputPreview(firstRun)
        
        if (!requestSuccess) {
          this.error = true
          this.errorText = 'Input preview request failed...'
        }
        
        this.requestTimer = setTimeout(() => {
          this.requestPreview()
        }, this.requestInterval)
      },
      
      getAudioLevel(channelNumber) {
        var channel = this.audio_levels.find(x => x.channel_num === channelNumber)
        if (channel && channel.max_hold_200ms > 0) {
          return channel.max_hold_200ms / 300
        }
        return 0
      }
      
    }
  }
</script>