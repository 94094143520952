<template>
  <v-card flat>
    <v-data-table
      v-model="selection"
      item-key="device_guid"
      :show-select="showSelect"
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="1000"
      :search="filter"
      sort-by="device_name"
      must-sort
      hide-default-footer
    >
      <template v-slot:item.device_name="{ item }">
        <device-label :device="item" />
      </template>
      <template v-slot:item.streaming_status="{ item }">
        <streaming-status-label :device="item" />
      </template>
      <template v-slot:item.device_ip="{ item }">
        <v-tooltip 
          open-on-hover
          open-delay="500"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.device_ip }}
            </span>
          </template>
          <span v-if="item.state.online">External IP: {{ item.state.external_ip }}</span>
          <span v-else>Device Offline</span>
        </v-tooltip>
      </template>
      <template v-slot:item.firmware_version="{ item }">
        <firmware-version-label :device="item" />
      </template>
    </v-data-table>
    <v-card-actions v-if="selection && selection.length > 0" class="px-0">
      <v-sheet
        rounded
        elevation="2"
        class="py-1 px-3"
        width="100%"
      >
        <span class="subtitle-2 mr-2">Actions</span>
        
        <span v-if="actionGroup == 'devices'">
          <v-btn
            small
            class="ma-1"
            outlined
            @click="deleteSelection()"
            :disabled="!canEdit"
          >
            Forget Device(s)
          </v-btn>
        </span>
        <span v-if="actionGroup == 'fleet'">
          <v-btn
            small
            class="ma-1"
            outlined
            @click="deleteSelection()"
            :disabled="!canEdit"
          >
            Remove Device(s)
          </v-btn>
        </span>
      </v-sheet>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceList',
    
    props: ['loading', 'value', 'filter', 'actionGroup', 'canEdit'],
    
    data() {
      return {
        error: false,
        errorText: '',
        
        items: [],
        selection: [],
        
        headers: [
          { text: 'Device', align: 'start', value: 'device_name', sortable: true },
          { text: 'Status', align: 'center', value: 'streaming_status', sortable: true},
          { text: 'Device IP', align: 'center', value: 'device_ip', sortable: true},
          { text: 'Firmware', align: 'center d-none d-lg-table-cell', value: 'firmware_version', sortable: true},
          { text: 'Serial', align: 'center d-none d-lg-table-cell text-no-wrap', value: 'serial_number', sortable: true },
          { text: 'MAC Address', align: 'center d-none d-xl-table-cell', value: 'mac_address', sortable: true },
        ],
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'organization']),
      
      showSelect() {
        if (this.actionGroup && this.canEdit) {
          return true
        }
        return false
      }
    },
    
    watch: {
      value() {
        this.items = this.value
        this.selection = []
      },
      selection() {
        this.selectionChanged()
      },
    },
    
    mounted() {
      if (this.value) {
        this.items = this.value
      }
      this.selection = []
    },
    
    methods: {
      selectionChanged() {
        this.$emit('selectionChanged', this.selection)
      },
      deleteSelection() {
        this.$emit('deleteSelection', this.selection)
      }
    } 
  }
</script>