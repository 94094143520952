<template>
  <div id="deviceInfo">
    <div v-if="!loading && !device.state" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">This device has no state</h2>
          <p>
            The device has not published state correctly, you may experience inconsistent behavior.
          </p>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="!loading && !device.hasCompleteState()" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">This device has incomplete state</h2>
          <p>
            The device has not published state correctly, you may experience inconsistent behavior.
          </p>
        </v-col>
      </v-row>
    </div>
    
    <v-progress-linear
      color="primary"
      indeterminate
      buffer-value="0"
      v-if="loading"
    ></v-progress-linear>
    
    <div class="ma-3" v-if="device.state">
      <v-row class="justify-left mb-5">
        <videon-chart-tile 
          :device="device"
          heading="CPU Utilization"
          icon="memory"
          :metrics="['processors|cpu_percent_used']"
          unit="%"
          :thresholdWarning="70"
          :thresholdCritical="90"
        />
        
        <videon-chart-tile 
          :device="device"
          heading="Memory Utilization"
          icon="memory"
          :metrics="['memory|memory_system_percent_used']"
          :fill="true"
          unit="%"
          :thresholdWarning="70"
          :thresholdCritical="90"
        />
        
        <videon-chart-tile 
          :device="device"
          heading="Network Output"
          icon="settings_ethernet"
          :metrics="['network|network_eth0_bytes_sent_per_second']"
          unit="bytes"
        />
        
        <videon-chart-tile 
          :device="device"
          heading="Storage Utilization"
          icon="save"
          :metrics="['filesystem|filesystem_/data_percent_used']"
          :fill="true"
          unit="%"
          :thresholdWarning="70"
          :thresholdCritical="90"
          v-if="productName == 'edgecaster'"
        />
        
        <videon-chart-tile 
          :device="device"
          heading="Storage Utilization"
          icon="save"
          :metrics="['filesystem|filesystem_/persist_percent_used']"
          :fill="true"
          unit="%"
          :thresholdWarning="70"
          :thresholdCritical="90"
          v-else-if="productName == 'edgecaster_max'"
        />
        
        <videon-chart-tile 
          :device="device"
          heading="Temperature"
          icon="device_thermostat"
          :metrics="['thermal|thermal_som_temp_current']"
          unit="°C"
          :thresholdWarning="80"
          :thresholdCritical="90"
          v-if="productName == 'edgecaster'"
        />
        
        <videon-chart-tile 
          :device="device"
          heading="Temperature"
          icon="device_thermostat"
          :metrics="['thermal|thermal_video-usr_temp_current']"
          unit="°C"
          :thresholdWarning="80"
          :thresholdCritical="90"
          v-else-if="productName == 'edgecaster_max'"
        />
      </v-row>

      <div v-if="device.state" class="ma-2">
        <div v-if="inputsState && inputsState.length > 0">
          <div 
            v-for="(input, index) in inputsState" 
            v-bind:key="index"
            class="mt-3"
          >
            <v-row><h5 class="text--disabled"><span v-if="!online">Last Known </span>{{ inputName(input) }} Status</h5></v-row>
            <v-row><v-divider /></v-row>
            <div v-if="online && isPreviewSupported && input.video_input_format != 'FORMAT_UNDEFINED'">
              <v-row class="mt-5">
                <v-col cols="12">
                  <input-preview :device_guid="device.device_guid" :input_id="input.id" />
                </v-col>
              </v-row>
            </div>
            <v-row class="mt-2">
              <videon-state-tile
                heading="Video Input"
              >
                {{ $helpers.getLabel(input.config.video_input_type) }}
              </videon-state-tile>
              <videon-state-tile
                heading="Video Format"
              >
                {{ $helpers.getFormatLabel(input.video_input_format) }}
              </videon-state-tile>
              <videon-state-tile
                heading="Audio Input"
              >
                {{ $helpers.getLabel(input.config.audio_input_type) }}
              </videon-state-tile>
              <videon-state-tile
                heading="Audio Detected"
                class="text-capitalize"
              >
                <v-icon v-if="input.audio_input_detected" class="black--text">volume_up</v-icon>
                <v-icon v-else class="red--text">volume_off</v-icon>
                {{ $helpers.getLabel(input.audio_input_detected) }}
              </videon-state-tile>
            </v-row>
          </div>
        </div>
        
        <div v-if="outputsState.length > 0" class="mt-3">
          <v-row><h5 class="text--disabled"><span v-if="!online">Last Known </span>Output Status</h5></v-row>
          <v-row><v-divider /></v-row>
          <v-row class="mt-2">
            <videon-state-tile
              v-for="(output, index) in outputsState" 
              v-bind:key="index"
              :heading="outputName(output)"
            >
              <output-status-label :output="output" />
            </videon-state-tile>
          </v-row>
        </div>
        
        <v-row class="mt-3"><h5 class="text--disabled">Device Info</h5></v-row>
        <v-row><v-divider /></v-row>
        <v-row>
          <videon-state-tile
            heading="Device Name"
          >
            {{ device.deviceName() }}
          </videon-state-tile>
          <videon-state-tile
            heading="Platform"
          >
            <product-label :device="device"/>
          </videon-state-tile>
          <videon-state-tile
            heading="Serial Number"
          >
            {{ device.state.serial_number }}
          </videon-state-tile>
          
          <videon-state-tile
            heading="Manufacture Date"
            class="secondary--text"
            v-if="isAdmin && device.manufacture_date"
          >
            {{ device.manufacture_date }}
          </videon-state-tile>
          
          <videon-state-tile
            heading="Firmware Version"
          >
            <firmware-version-label :device="device"/>
          </videon-state-tile>
          <videon-state-tile
            heading="MAC Address"
          >
            {{ device.state.mac_address_current }}
          </videon-state-tile>
          
          <videon-state-tile
            heading="Last Boot"
          >
            {{ device.state.last_boot | moment("YYYY/MM/DD hh:mm:ss a") }}
          </videon-state-tile>
          <videon-state-tile
            heading="Device Uptime"
          >
            {{ uptime }}
          </videon-state-tile>
          
          
          <videon-state-tile
            heading="Last State Update"
          >
            {{ device.state.last_state_update | moment("YYYY/MM/DD hh:mm:ss a") }}
          </videon-state-tile>
          
          <videon-state-tile
            heading="Last API Request ID"
            class="text--disabled"
            v-if="isAdmin && debug"
          >
            {{ device.last_api_request_id }}
          </videon-state-tile>
        </v-row>
        
        <v-row><h5 class="text--disabled">Device State</h5></v-row>
        <v-row><v-divider /></v-row>
        <v-row>
          <videon-state-tile
            heading="Device Online"
            class="text-capitalize"
          >
            {{ device.isOnline() }}
          </videon-state-tile>
          <videon-state-tile
            heading="Ready For Commands"
            class="text-capitalize"
          >
            {{ device.state.ready_for_commands }}
          </videon-state-tile>
          <videon-state-tile
            heading="Internal IP"
          >
            {{ device.deviceIP() }}
            <span v-if="device.IPScheme() == 'dhcp'" class="font-weight-thin">(DHCP)</span>
          </videon-state-tile>
          <videon-state-tile
            heading="External IP"
          >
            {{ externalIP }}
          </videon-state-tile>
          <videon-state-tile
            heading="Processor State"
          >
            <processors-status-label :device="device"/>
          </videon-state-tile>
        </v-row>
        
      </div>
    </div>
    
    <device-events class="mt-5" v-if="!loading" heading="Device Events" targetObject="devices" :targetGUID="device_guid" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  
  import DeviceEvents from '../components/DeviceEvents.vue'
  import InputPreview from '../../device/components/InputPreview.vue'
  
  export default {
    name: 'DeviceInfo',
    components: {
      DeviceEvents,
      InputPreview
    },
    
    props: ['device_guid', 'device'],
    
    data() {
      return {
        debug: false,
        inputPreviewSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.3.0'),
      }
    },
    
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isAdmin', 'isDeviceAdmin', 'organization']),
      
      loading() {
        if (this.device && !this.device.ready) {
          return true
        }
        return false
      },
      online() {
        return this.device.isOnline()
      },
      icon() {
        if (this.device) {
          return this.device.icon()
        }
        return ''
      },
      
      deviceName() {
        if (this.device) {
          return this.device.deviceName()
        }
        
        return this.device_guid
      },
      
      productName() {
        if (this.device) {
          return this.device.product_name
        }
        
        return 'edgecaster'
      },
      
      externalIP() {
        if (this.device && this.device.state) {
          if (this.device.state.external_ip) {
            return this.device.state.external_ip
          }
        }
        return 'Unknown'
      },
      
      uptime() {
        if (!this.device.isOnline()) {
          return 'Offline'
        } else if (this.device && this.device.state) {
          if (this.device.state.last_boot && this.device.last_api_request_id) {
            return moment(this.device.state.last_boot).fromNow(true)
          }
        }
        return 'Unknown'
      },
      
      inputsState() {
        return this.device.inputsState()
      },
      
      outputsState() {
        return this.device.outputsState()
      },
      
      isPreviewSupported() {
        if (this.device.thumbnailEnabled() && this.$helpers.versionCheck(this.inputPreviewSupportedVersion, this.device.cloud_version)) {
          return true
        }
        return false
      }
    },
    methods: {
      inputName(shadowDocument) {
        if (shadowDocument.config.name) {
          return this.$helpers.htmlDecode(shadowDocument.config.name)
        }
        
        return 'Input #' + shadowDocument.id
      },
      outputName(shadowDocument) {
        if (shadowDocument.config.name) {
          return this.$helpers.htmlDecode(shadowDocument.config.name)
        }
        
        var outputName = 'Output #' + shadowDocument.id
        if (shadowDocument.type) {
          outputName += ' (' + this.$helpers.getLabel(shadowDocument.type) + ')'
        }
        return outputName
      },
    }
  }
</script>