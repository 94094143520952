<template>
  <v-card 
    class="mx-auto"
  > 
    <v-dialog
      v-model="showDeleteProfile"
      max-width="400px"
      v-if="this.selectedProfile"
    >
      <videon-card heading="Delete Entitlement Profile" showClose="true" @close="showDeleteProfile = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text text-no-wrap">{{ this.selectedProfile.profile_name }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteOrg">
            <v-text-field
              v-model="deleteProfileReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteProfile = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteProfile"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>



    <v-dialog
      v-model="showUnsavedChanges"
      max-width="500px"
      persistent
    >
      <videon-card heading="Unsaved Changes">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            You have unsaved changes, are you sure you wish to leave?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" class="ma-2 mt-0">
            <v-btn color="primary" text @click="showUnsavedChanges = false">No</v-btn>
            <v-btn color="primary" text @click="selectedProfile = tenativeSelectedProfile; showUnsavedChanges = false">Yes</v-btn>
          </v-row>
        </v-card-actions>
      </videon-card>
    </v-dialog>



    <v-dialog v-if="selectedProfile" v-model="showBaseEntitlementWarning" max-width="500px" persistent>
      <videon-card heading="Confirm changes to base entitlement">
        <v-card-text>
          <p class="subtitle-1 mt-4">
            Changes to this base entitlement will apply to all {{ $helpers.getLabel(selectedProfile.profile_platform) }} devices.
            This is a significant change.
          </p>

          <p class="subtitle-1 mt-2">
            Type BASE ENTITLEMENT below to confirm changes.
          </p>

          <v-text-field
            v-model="baseEntitlementConfirmation"
            label="Confirmation"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" class="ma-2 mt-0">
            <v-btn color="primary" text @click="showBaseEntitlementWarning = false">No</v-btn>
            <v-btn
              @click="saveChanges(true)"
              :disabled="baseEntitlementConfirmation.toUpperCase() != 'BASE ENTITLEMENT'"
              color="primary"
              text
            >Yes</v-btn>
          </v-row>
        </v-card-actions>
      </videon-card>
    </v-dialog>



    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        Entitlement Profiles
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined small color="primary" @click="newEntitlementProfile"><v-icon>add</v-icon> New Profile</v-btn>
    </v-toolbar>
    
    <v-divider/>

    <v-card-text>
      <div v-if="loading">
        <v-progress-linear
          color="primary"
          indeterminate
          buffer-value="0"
        ></v-progress-linear>
      </div>
      <v-row>
        <v-col cols="3">
          <v-list v-if="profiles.length > 0" dense>
            <v-text-field
              v-model="profileSearch"
              label="Profile Search"
              dense
              outlined
            ></v-text-field>

            <v-list-item-group
              v-model="tenativeSelectedProfile"
              color="primary"
            >
              <v-list-item
                v-for="(entitlement, index) in baseProfiles"
                :key="index"
                :value="entitlement"
              >
                <v-list-item-icon class="mr-1">
                  <v-icon color="secondary">collections_bookmark</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="entitlement.profile_name || entitlement.profile_guid"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider></v-divider>

            <v-list-item-group
              v-model="tenativeSelectedProfile"
              color="primary"
            >
              <v-list-item
                v-for="(entitlement, index) in regularProfiles"
                :key="index"
                :value="entitlement"
              >
                <v-list-item-icon class="mr-1">
                  <v-icon>collections_bookmark</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="entitlement.profile_name || entitlement.profile_guid"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        
        <v-col cols="9">
          <v-alert dense outlined type="error" v-if="error">
            {{ errorText }}
          </v-alert>
          
          <v-card flat v-if="selectedProfile">
            <v-card-text>
              <v-form @submit.prevent="saveChanges">
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      name="profile_name"
                      label="Profile Name"
                      autocomplete="off"
                      v-model="selectedProfile.profile_name"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-select
                      name="profile_platform"
                      label="Profile Platform"
                      v-model="selectedProfile.profile_platform"
                      :disabled="!!selectedProfile.profile_guid"
                      item-text="label"
                      item-value="product"
                      :items="productList"
                      :return-object="false"
                      required
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>

                <v-alert dense outlined type="warning" v-if="isBaseProfile(selectedProfile)">
                  Editing a base entitlement. Changes will be applied to all {{ $helpers.getLabel(selectedProfile.profile_platform) }} devices.
                </v-alert>

                <v-progress-linear
                  v-if="!capabilityProfile"
                  color="primary"
                  indeterminate
                  buffer-value="0"
                ></v-progress-linear>

                <v-simple-table
                  v-else
                  fixed-header
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Enabled
                        </th>
                        <th class="text-left">
                          Key
                        </th>
                        <th>
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <entitlement-feature
                        v-for="(capability, key) in capabilityProfile"
                        :key="key"
                        :capability="capability"

                        :value="selectedProfile.properties[capability.feature]"
                        @update="updateFeature"
                      ></entitlement-feature>
                    </tbody>
                  </template>
                </v-simple-table>

                <div class="mt-10 text-right">
                  <v-btn
                    class="ma-1"
                    small
                    text
                    @click="showDeleteProfile = true"
                    v-if="selectedProfile.profile_guid"
                    :disabled="isBaseProfile(selectedProfile)"
                  >
                    <v-icon>delete</v-icon> Delete Profile
                  </v-btn>
                  <v-btn
                    small
                    class="ml-1"
                    color="primary" 
                    @click="saveChanges(false)"
                    :loading="loading"
                    :disabled="!hasChanges"
                  >
                    Save Profile
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import Vue from 'vue'

  import EntitlementFeature from './components/EntitlementFeature.vue'
  
  export default {
    name: 'EntitlementProfiles',

    components: {
      EntitlementFeature
    },
    data() {
      return {
        section: false,
        
        loading: false,

        error: false,
        errorText: '',

        profileSearch: '',

        selectedProfile: false,
        tenativeSelectedProfile: false,
        hasChanges: false,
        
        showDeleteProfile: false,
        deleteProfileReason: '',

        showUnsavedChanges: false,

        showBaseEntitlementWarning: false,
        baseEntitlementConfirmation: ''
      }
    },
    mounted() {
      this.loading = true
      this.$entitlementProfiles.allEntitlementProfiles().then(() => {
        this.loading = false
      })
    },
    watch: {
      selectedProfile: {
        deep: true,
        handler(to, from) {
          if (to == from && this.selectedProfile && this.selectedProfile.profile_name != '') {
            this.hasChanges = true
          } else {
            this.hasChanges = false
          }
        }
      },

      tenativeSelectedProfile() {
        if (this.hasChanges) {
          this.showUnsavedChanges = true
        } else {
          this.selectedProfile = this.tenativeSelectedProfile
        }
      }
    },
    computed: {
      profiles() {
        var searchString = this.profileSearch.toLowerCase()

        return this.$entitlementProfiles.profiles.filter(
          profile => !searchString || profile.profile_name.toLowerCase().match(searchString)
        )
      },

      baseProfiles() {
        return this.profiles.filter(profile => this.isBaseProfile(profile))
      },

      regularProfiles() {
        return this.profiles.filter(profile => !this.isBaseProfile(profile))
      },
      
      productList() {
        return this.$capabilities.allProducts().map(
          product => ({ label: this.$helpers.getLabel(product), product })
        )
      },

      capabilityProfile() {
        var profile = this.$capabilities.getCapabilityProfile(this.selectedProfile.profile_platform)
        
        return profile.ready && profile.capabilities
      }
    },
    methods: {
      newEntitlementProfile() {
        this.selectedProfile = Vue.observable({ 'profile_name': '', 'profile_platform': 'edgecaster', 'properties': {} })
        this.hasChanges = false
        this.error = false
      },

      updateFeature(feature, value) {
        this.hasChanges = true

        if (value !== undefined) {
          Vue.set(this.selectedProfile.properties, feature, value)
        } else {
          Vue.delete(this.selectedProfile.properties, feature)
        }
      },
      
      saveChanges(sure) {
        if (!this.selectedProfile) {
          return
        }

        if (this.isBaseProfile(this.selectedProfile)) {
          if (!sure) {
            this.showBaseEntitlementWarning = true
            return
          } else {
            this.showBaseEntitlementWarning = false
            this.baseEntitlementConfirmation = ''
          }
        }
        
        this.loading = true
        
        this.error = false
        this.errorText = ''
        
        var entitlementProfile = {
          'profile_name': this.selectedProfile.profile_name,
          'profile_platform': this.selectedProfile.profile_platform,
          'properties': this.selectedProfile.properties
        }
        
        console.log('EntitlementProfiles saveChanges', entitlementProfile)
        
        if (!this.selectedProfile.profile_guid) {
          // new profile
          this.axios.post('/entitlements/profiles', entitlementProfile)
          .then((response) => {
            console.log('EntitlementProfiles saveChanges response', response)
            
            this.selectedProfile = false
            this.$entitlementProfiles.allEntitlementProfiles().then(() => {
              this.loading = false
            })
            
            this.hasChanges = false
          }).catch((error) => {
            console.log('EntitlementProfiles saveChanges error', error)
            
            this.error = true
            this.errorText = this.prettifyErrorMessage(this.$helpers.parseError(error))
            
            this.loading = false
          })
          
        } else {
          // existing profile
          this.axios.patch('/entitlements/profiles/' + this.selectedProfile.profile_guid, entitlementProfile)
          .then((response) => {
            console.log('EntitlementProfiles saveChanges response', response)
          
            this.$entitlementProfiles.allEntitlementProfiles().then(() => {
              this.loading = false
            })
            
            this.hasChanges = false
          }).catch((error) => {
            console.log('EntitlementProfiles saveChanges error', error)
          
            this.error = true
            this.errorText = this.prettifyErrorMessage(this.$helpers.parseError(error))
            
            this.loading = false
          })
          
        }

      },
      confirmDeleteProfile() {
        if (this.selectedProfile.profile_guid && this.deleteProfileReason) {
          this.showDeleteProfile = false
          this.loading = true
          
          this.error = false
          this.errorText = ''
          
          console.log('deleteProfile', this.selectedProfile)
        
          this.axios.delete('/entitlements/profiles/' + this.selectedProfile.profile_guid, {'data': {'reason': this.deleteProfileReason}})
          .then((response) => {
            console.log('EntitlementProfiles deleteProfile response', response)
            
            this.selectedProfile = false
            this.deleteProfileReason = ''
            
            this.$entitlementProfiles.allEntitlementProfiles().then(() => {
              this.loading = false
            })
            
            this.error = false
            this.errorText = ''
            
            this.loading = false
          }).catch((error) => {
            console.log('EntitlementProfiles deleteProfile error', error)
        
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
        }
      },
      isBaseProfile(profile) {
        return profile.profile_guid && profile.profile_guid.startsWith('BASE_')
      },
      prettifyErrorMessage(message) {
        // NOTE: If we get something like an out of range error, we get something like:
        //   Feature 'daemon.input.video.hdmi.limit' must be between 1 and 16 (inclusive)
        // That is ugly, so we'll try to replace it to be something like:
        //   Feature 'HDMI Input Instance Limit' must be between 1 and 16 (inclusive)

        message.match(/\b([a-z]+\.)+[a-z]+\b/g)?.forEach(possible_label => {
          var label = this.$helpers.getLabel(possible_label)

          if (label != possible_label) {
            message = message.replace(possible_label, label)
          }
        })

        return message
      }
    }
  }
</script>