<template>
  <div class="d-inline-block text-center">
    <v-dialog
      v-model="showState"
      max-width="440px"
    >
      <videon-card :heading="device.deviceName() + ' Processor State'" showClose="true" @close="showState = false">
        <div v-if="!device.state" class="pa-5 pb-2">
          <v-alert outlined type="error">
            Device state is unknown
          </v-alert>
        </div>
        <v-card-text v-if="device.state">
          <v-row class="mt-2">
            <videon-state-tile
              heading="CPU0 Throttling"
              class="text-capitalize"
            >
              {{ device.state.cpu_CPU0_throttling }}
            </videon-state-tile>
            <videon-state-tile
              heading="CPU1 Throttling"
              class="text-capitalize"
            >
              {{ device.state.cpu_CPU1_throttling }}
            </videon-state-tile>
            <videon-state-tile
              heading="CPU2 Throttling"
              class="text-capitalize"
            >
              {{ device.state.cpu_CPU2_throttling }}
            </videon-state-tile>
            <videon-state-tile
              heading="CPU3 Throttling"
              class="text-capitalize"
            >
              {{ device.state.cpu_CPU3_throttling }}
            </videon-state-tile>
          </v-row>
      
          <v-row><v-divider /></v-row>
      
          <v-row>
            <videon-state-tile
              heading="GPU Throttling"
              class="text-capitalize"
            >
              {{ device.state.gpu_throttling }}
            </videon-state-tile>
            <videon-state-tile
              heading="GPU Governor"
              class="text-capitalize"
            >
              {{ device.state.gpu_governor }}
            </videon-state-tile>
          </v-row>
          
          
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-icon v-if="status.warning" color="red">warning</v-icon> <span @click="showState = true" style="cursor: pointer;">{{ status.label }}</span>
  </div>
</template>

<script>
  
  export default {
    name: 'ProcessorsStatusLabel',
    props: ['device'],
    computed: {
      status() {
        if (this.device.state) {
          if (this.device.state.cpu_CPU0_throttling || this.device.state.cpu_CPU1_throttling || this.device.state.cpu_CPU2_throttling || this.device.state.cpu_CPU3_throttling) {
            if (this.device.state.gpu_throttling) {
              return {'label': 'CPU & GPU throttling', 'warning': true}
            } else {
              return {'label': 'CPU throttling', 'warning': true}
            }
          }
          return {'label': 'Good'}
        }
        return {'label': 'Unknown'}
      },
    },
    data() {
      return {
        showState: false,
      }
    }
  }
</script>