<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Video Encoder</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Encoder Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <div v-if="isDynamicSupported">
      <v-row><h5 class="text--disabled">Input Selection</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-5">
        <v-col cols="4">
          <v-checkbox
            v-model="isMBRMember"
            label="MBR Group Member"
            hide-details
            class="mt-2 mb-5"
            :disabled="!canEdit || syncGroups.length == 0"
          ></v-checkbox>
        </v-col>
        <v-col lg="8" cols="8" v-if="isDynamicSupported && !isMBRMember">
          <input-selector :device_guid='device_guid' :canEdit="canEdit" v-model="config.in_channel_id" @change="configChanged" />
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Video Scaling</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="12" v-if="isVerticalVideo">
        <v-select
          v-model="config.scaling_resolution"
          :items="scaling_resolutionVerticalValues"
          label="Video Scaling"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" v-else>
        <v-select
          v-model="config.scaling_resolution"
          :items="scaling_resolutionValues"
          label="Video Scaling"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12" class="pt-0">
        <v-checkbox
          v-model="config.limit_to_30_fps"
          label="Limit to Maximum 30 FPS"
          @change="configChanged"
          :disabled="!canEdit || isMBRMember"
          :messages="(showLimitTo30Warning) ? 'This can cause invalid stream output when Closed Caption data is received over SDI' : ''"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="6">
        <v-select
          v-model="config.bitrate_mode"
          :items="bitrate_modeValues"
          label="Encoding Mode"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="config.bitrate"
          label="Video Bitrate (kbps)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="bitrateValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row class="mb-5" v-if="config.allow_outputs_to_adjust_bitrate !== undefined">
      <v-col cols="12">
        <v-checkbox
          v-model="config.allow_outputs_to_adjust_bitrate"
          label="Allow Outputs to Adjust Bitrate"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="allow_outputs_to_adjust_bitrateValidation"
          class="mt-0"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="config.selected_codec"
          :items="selected_codecValues"
          label="Video Codec"
          @change="configChanged"
          :disabled="!canChangeCodec"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row v-if="config.selected_codec == 'H264'">
      <v-col cols="12">
        <v-select
          v-model="config.h264_profile"
          :items="h264_profileValues"
          label="H.264 Profile"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="config.selected_codec == 'H265'">
      <v-col cols="12">
        <v-select
          v-model="config.h265_profile"
          :items="h265_profileValues"
          label="H.265 Profile"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="6">
        <v-text-field
          v-if="config.keyframe_unit == 'FRAMES'"
          v-model.number="config.keyframe_interval"
          label="Keyframe Interval (frames)"
          @input="configChanged"
          :disabled="!canEdit || isMBRMember"
          type="number"
          :rules="keyframe_unitFramesValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
        <v-text-field
          v-else
          v-model.number="config.keyframe_interval"
          label="Keyframe Interval (milliseconds)"
          @input="configChanged"
          :disabled="!canEdit || isMBRMember"
          type="number"
          :rules="keyframe_unitMSValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="config.keyframe_unit"
          :items="keyframe_unitValues"
          label="Keyframe Unit"
          @change="configChanged"
          :disabled="!canEdit || isMBRMember"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.latency_mode"
          :items="latency_modeValues"
          label="Quality / Latency"
          @change="configChanged"
          :disabled="!canEdit || isMBRMember"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Additional Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    
    <v-row class="mb-3">
      <v-col cols="12" class="pt-0">
        <v-checkbox
          v-model="config.klv_timestamp_enabled"
          label="Enable KLV Timecode Insertion for Video Frames"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row class="mb-3" v-if="config.cc_processing_enabled !== undefined">
      <v-col cols="12" class="pt-0">
        <v-checkbox
          v-model="config.cc_processing_enabled"
          label="Enable processing for CEA-708/608 captions"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Encoder Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ encoder }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteEncoder()"
      >
        Delete Encoder
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import InputSelector from '../components/InputSelector.vue'
  
  export default {
    name: 'VideoEncoderDocument',
    
    props: ['encoder', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      InputSelector
    },
    
    data() {
      return {
        limitTo30WarningVersion: this.$helpers.parseVersion('device', 'v9.0.5'),
        backpressureSettingsVersion: this.$helpers.parseVersion('cloud', 'v1.11.0'),
        ccProcessingVersion: this.$helpers.parseVersion('device', 'v10.2.1'),
        strictCBRVersion: this.$helpers.parseVersion('device', 'v10.2.1'),
        
        valid: true,
        
        id: false,
        
        h264_level: '',
        h265_level: '',
        
        scaling_resolutionValues: [
          { 'value': 'RES_PASSTHROUGH', 'text': 'Passthrough' },
          { 'value': 'RES_320X180', 'text': '320x180p (16:9)' },
          { 'value': 'RES_480X270', 'text': '480x270p (16:9)' },
          { 'value': 'RES_640X360', 'text': '640x360p (16:9)' },
          { 'value': 'RES_854X480', 'text': '854x480p (16:9)' },
          { 'value': 'RES_720X576', 'text': '720x576p (4:3)' },
          { 'value': 'RES_960X540', 'text': '960x540p (16:9)' },
          { 'value': 'RES_1280X720', 'text': '1280x720p (16:9)' },
          { 'value': 'RES_1920X1080', 'text': '1920x1080p (16:9)' },
          { 'value': 'RES_3840X2160', 'text': '3840x2160p (16:9)' },
        ],
        
        scaling_resolutionVerticalValues: [
          { 'value': 'RES_PASSTHROUGH', 'text': 'Passthrough' },
          { 'value': 'RES_180X320', 'text': '180x320p (9:16)' },
          { 'value': 'RES_270X480', 'text': '270x480p (9:16)' },
          { 'value': 'RES_360X640', 'text': '360x640p (9:16)' },
          { 'value': 'RES_480X854', 'text': '480x854p (9:16)' },
          { 'value': 'RES_576X720', 'text': '576x720p (3:4)' },
          { 'value': 'RES_540X960', 'text': '540x960p (9:16)' },
          { 'value': 'RES_720X1280', 'text': '720x1280p (9:16)' },
          { 'value': 'RES_1080X1920', 'text': '1080x1920p (9:16)' },
          { 'value': 'RES_2160X3840', 'text': '2160x3840p (9:16)' },
        ],
        
        selected_codecValues: [
          { 'value': 'H264', 'text': 'H.264 (AVC)' },
          { 'value': 'H265', 'text': 'H.265 (HEVC)' },
        ],
        
        h264_profileValues: [
          { 'value': 'PROFILE_HIGH', 'text': 'High Profile' },
          { 'value': 'PROFILE_MAIN', 'text': 'Main Profile' },
          { 'value': 'PROFILE_BASELINE', 'text': 'Baseline Profile' },
        ],
        
        h265_profileValues: [
          { 'value': 'PROFILE_MAIN', 'text': 'Main Profile' },
        ],
        
        keyframe_unitValues: [
          { 'value': 'MILLISECONDS', 'text': 'Milliseconds' },
          { 'value': 'FRAMES', 'text': 'Frames' },
        ],
        
        config: {
          active: false,
          in_channel_id: -1,
          
          name: '', 
          
          scaling_resolution: 'RES_PASSTHROUGH',
        
          limit_to_30_fps: false,
          
          selected_codec: 'H264',
          
          bitrate_mode: 'variable',
          bitrate: 10000,
        
          h264_profile: 'PROFILE_MAIN',
          h265_profile: 'PROFILE_MAIN',
        
          keyframe_interval: 1000,
          keyframe_unit: 'MILLISECONDS',
          latency_mode: 'NORMAL',
        
          sync_group_id: -1, // TODO
        
          klv_timestamp_enabled: false,
        },
        
        // validations
        bitrateValidation: [
          v => (!isNaN(v)) || 'Value must be a number',
          v => (v >= 100) || 'Value must be >= 100',
          v => (v <= 50000) || 'Value must be <= 50000'
        ],
        
        keyframe_unitFramesValidation:  [
          v => (!isNaN(v)) || 'Value must be a number',
          v => (v >= 1) || 'Value must be >= 1',
          v => (v <= 1000) || 'Value must be <= 1000'
        ],
        
        keyframe_unitMSValidation:  [
          v => (!isNaN(v)) || 'Value must be a number',
          v => (v >= 41) || 'Value must be >= 41',
          v => (v <= 16666) || 'Value must be <= 16666'
        ],
      }
    },
    
    watch: {
      encoder() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      syncGroups() {
        var shadows = this.$deviceShadows.getDeviceShadow(this.device_guid, 'Encoders')
        var encoders = (shadows.reported.state) ? shadows.reported.state : []
        return encoders.filter(encoder => encoder.type == 'sync_group')
      }, 
      
      latency_modeValues() {
        if (this.device.product_name == 'edgecaster_max') {
          return [
            { 'value': 'NORMAL', 'text': 'Normal' },
            { 'value': 'LOWEST', 'text': 'Lowest' },
          ]
        } else {
          return [
            { 'value': 'HIGH', 'text': 'High' },
            { 'value': 'NORMAL', 'text': 'Normal' },
            { 'value': 'LOW', 'text': 'Low' },
            { 'value': 'LOWEST', 'text': 'Lowest' },
          ]
        }
      },
      
      bitrate_modeValues() 
      {
        var modeValues = [
          { 'value': 'constant', 'text': 'Constant Bitrate' },
          { 'value': 'variable', 'text': 'Variable Bitrate' },
        ]
        
        if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.strictCBRVersion, this.device.daemon_version)) {
          modeValues.push({ 'value': 'constant_strict', 'text': 'Strict Constant Bitrate' })
        }
        
        return modeValues
      },
      
      isMBRMember: {
        get: function() {
          if (this.config.sync_group_id > 0) {
            return true
          }
          return false
        }, 
        set: function(newValue) {
          if (newValue === true) {
            if (this.syncGroups.length > 0) {
              var syncGroup = this.syncGroups[0]
              this.config.sync_group_id = syncGroup.id
            }
          } else {
            this.config.sync_group_id = -1
          }
        }
      },
      
      showLimitTo30Warning() {
        // if current version is older than limitTo30WarningVersion, show warning
        if (this.$helpers.isNewerVersion(this.device.daemon_version, this.limitTo30WarningVersion)) {
          return true
        }
        return false
      },

      isVerticalVideo() {
        var shadows = this.$deviceShadows.getDeviceShadow(this.device_guid, 'Inputs')
        var inputs = (shadows.reported.state) ? shadows.reported.state : []
        
        if (inputs.length > 0) {
          // only 1 input... for now...
          var input = inputs[0]
          if (input.config.video_rotation == 'ROTATE_90' || input.config.video_rotation == 'ROTATE_270') {
            return true
          }
        }
        return false
      },
      
      isDynamicSupported() {
        return this.$versioning.isDynamicSupported(this.device)
      },
      canChangeCodec() {
        if (!this.canEdit || (this.isDynamicSupported && !this.isNew)) {
          return false
        }
        return true
      },
      
      allow_outputs_to_adjust_bitrateValidation() {
        if (this.config.allow_outputs_to_adjust_bitrate == true && this.config.bitrate_mode != 'variable') {
          return ['Encoding Mode must be Variable Bitrate in order to enable allowing outputs to adjust bitrate.']
        }
        return []
      },
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        // check for validation errors
        if (this.config.bitrate > 50000 || this.config.bitrate < 100) {
          console.log('VideoEncoderDocument ' + this.device_guid + ' validation error: Video Bitrate must be between 100 and 50000', this.config)
          validationErrors = true
        } 
        
        if (this.config.keyframe_unit == 'FRAMES' && (this.config.keyframe_interval < 1 || this.config.keyframe_interval > 1000)) {
          console.log('VideoEncoderDocument ' + this.device_guid + ' validation error: Keyframe Interval must be between 1 and 1000 frames', this.config)
          validationErrors = true
        } 
        
        if (this.config.keyframe_unit != 'FRAMES' && (this.config.keyframe_interval < 41 || this.config.keyframe_interval > 16666)) {
          console.log('VideoEncoderDocument ' + this.device_guid + ' validation error: Keyframe Interval must be between 41 and 16666 milliseconds', this.config)
          validationErrors = true
        }
        
        if (this.config.allow_outputs_to_adjust_bitrate == true && this.config.bitrate_mode != 'variable') {
          console.log('VideoEncoderDocument ' + this.device_guid + ' validation error: allow_outputs_to_adjust_bitrate enabled, but bitrate_mode != variable', this.config)
          validationErrors = true
        }
        
        // fix for weird daemon problem
        if (this.config.selected_codec == 'H265') {
          this.config.h264_profile = 'PROFILE_HIGH'
        }
        
        console.log('VideoEncoderDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.encoder && this.encoder.config) {
          Object.keys(this.encoder).map((key) => {
            this[key] = this.encoder[key]
          })
          
          // htmlDecode name properties
          if (this.config.name) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // populate transient values
        if (this.isNew) {
          console.log('VideoEncoderDocument populating transient properties')
          
          if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.backpressureSettingsVersion, this.device.cloud_version)) {
            if (!this.config.allow_outputs_to_adjust_bitrate) {
              this.$set(this.config, 'allow_outputs_to_adjust_bitrate', false)
            }
          }
          
          if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.ccProcessingVersion, this.device.daemon_version)) {
            if (!this.config.cc_processing_enabled) {
              this.$set(this.config, 'cc_processing_enabled', false)
            }
          }
        }
        
        
        this.configChanged()
      },
      
      deleteEncoder() {
        console.log('VideoEncoderDocument ' + this.device_guid + ' deleteEncoder')
        this.$emit('deleteEncoder')
      }
    }
  }
</script>