<template>
  <div>
    <v-dialog
      v-model="showAddDevices"
      max-width="900px"
    >
      <videon-card heading="Add Devices to Fleet" showClose="true" @close="showAddDevices = false" v-if="showAddDevices">
        <v-card-text>
          <p class="mb-0">Add existing devices to this fleet. To adopt a new device, go to <router-link to="/devices">All Devices</router-link>.</p>
          <v-form @submit.prevent="addDevices">
            
            <device-selector v-model="selectedDevices" :disableExisting="true" :existing="fleet.devices"/>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showAddDevices = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="addDevices"
              >
                Add Devices
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <device-status-tiles :status="fleet.status" />
    
    <v-card flat>
      <v-card-title>
        Devices
        <v-spacer/>
        <v-btn
          small
          color="primary"
          @click="showAddDevices = true"
          tooltip="Add Devices to Fleet"
          v-if="canEdit"
        >
          <v-icon>mdi-plus</v-icon> Add Devices
        </v-btn>
      </v-card-title>
    
      <v-card-text>
        <!-- eslint-disable-next-line  -->
        <device-list v-model="fleet.devices" :loading="isLoading" actionGroup="fleet" @deleteSelection="deleteDevices" :canEdit="canEdit"/>
      </v-card-text>
    </v-card>
    <device-events heading="Recent Fleet Events" :showDevice="true" targetObject="fleets" :targetGUID="fleet_guid" :startTime="eventStartTime" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import DeviceList from '../../device/components/DeviceList.vue'
  
  import DeviceSelector from '../../device/components/DeviceSelector.vue'
  import DeviceEvents from '../../device/components/DeviceEvents.vue'
  
  import moment from 'moment'
  
  export default {
    name: 'FleetDevices',
        
    components: {
      DeviceList,
      DeviceSelector,
      DeviceEvents
    },
    
    props: ['fleet_guid', 'fleet', 'canEdit'],
    
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
      
        showAddDevices: false,
        selectedDevices: [],
        
        deviceHeaders: [
          { text: 'Device', align: 'start', value: 'device_name', sortable: true},
          { text: 'Status', align: 'center', value: 'streaming_status', sortable: true},
          { text: 'Firmware', align: 'center d-none d-lg-table-cell', value: 'firmware_version', sortable: true},
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
        
        eventStartTime: moment().subtract('5', 'days').toISOString(),
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isOrgAdmin']),
      
      isLoading() {
        if (this.loading) {
          return true
        } else if (!this.fleet.ready) {
          return true
        }
        return false
      }
    },
    
    methods: {
      addDevices() {
        this.showAddDevices = false
        this.loading = true
        
        var deviceGuids = this.selectedDevices.map(device => device.device_guid)
        console.log('FleetDevices addDevices', deviceGuids)
        
        if (deviceGuids.length > 0) {
          this.axios.post('/fleets/' + this.fleet_guid + '/devices', {'devices': deviceGuids})
          .then((response) => {
            console.log('FleetDevices addDevices response', response)
            
            this.selectedDevices = []
            
            this.fleet.fetchDevices()
            
            this.loading = false
            this.error = false
            this.errorText = ''
          }).catch((error) => {
            console.log('FleetDevices addDevices error', error)
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
      manageDevice(item) {
        console.log('FleetDevices manageDevice', item)
        this.$router.push('/devices/' + item.device_guid)
      },
      
      deleteDevices(devices) {
        this.loading = true
        
        var deviceGuids = devices.map(device => device.device_guid)
        console.log('FleetDevices deleteDevices', deviceGuids)
        
        this.axios.delete('/fleets/' + this.fleet_guid + '/devices', {data: {'devices': deviceGuids}})
        .then((response) => {
          console.log('FleetDevices deleteDevice response', response)
          
          this.fleet.fetchDevices()
          this.loading = false
          
          this.error = false
          this.errorText = ''
        }).catch((error) => {
          console.log('FleetDevices deleteDevice error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
    }
  }
</script>