<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showCreateOrg"
      max-width="600px"
    >
      <videon-card heading="Create Organization" showClose="true" @close="showCreateOrg = false">
        <v-card-text>
          <v-form @submit.prevent="createOrg">
            <v-text-field
              v-model="newOrgName"
              label="Organization Name"
              required
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showCreateOrg = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createOrg"
              >
                Create
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showDeleteOrg"
      max-width="400px"
    >
      <videon-card heading="Delete Organization" showClose="true" @close="showDeleteOrg = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text text-no-wrap">{{ selectedOrg.org_name }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteOrg">
            <v-text-field
              v-model="deleteOrgReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteOrg = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteOrg"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
  
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        Organizations
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-2">
        {{ errorText }}
      </v-alert>
    
      <v-container>
        <v-row class="mx-2 my-1" align="end">
          <v-text-field
            class="mr-5"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
          ></v-text-field>

          <v-btn
            small
            color="primary"
            class="mt-5"
            @click="showCreateOrg = true"
            tooltip="Create New Organization"
          >
            <v-icon>mdi-plus</v-icon> Create Org
          </v-btn>
        </v-row>
    
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              mobile-breakpoint="0"
              sort-by="org_name"
              hide-default-footer
            >
              <template v-slot:item.org_name="{ item }">
                <span class="text-no-wrap" style="cursor: pointer;" :class="isLastOrganizationGuid(item) ? 'primary--text' : ''" @click="editItem(item)">
                  <v-icon class="mr-2" :color="isLastOrganizationGuid(item) ? 'primary' : ''">house</v-icon>
                  <span class="subtitle-1">{{ $helpers.htmlDecode(item.org_name) }}</span>
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  class="ma-1 d-none d-md-inline"
                  x-small
                  outlined
                  @click="deleteItem(item)"
                  v-if="item.access > 200"
                >
                  Delete Org
                </v-btn>
              </template>
            </v-data-table>
            <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  
  export default {
    name: 'OrgListing',
    data() {
      return {
        error: false,
        errorText: '',
      
        showCreateOrg: false,
        newOrgName: '',
      
        showDeleteOrg: false,
        selectedOrg: false,
        deleteOrgReason: '',
      
        search: '',
        awaitingSearch: false,
      
        headers: [
          { text: 'Organization Name', align: 'start', value: 'org_name', sortable: true },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
      
        loading: true,
      
        items: [],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
      
        paginationTokens: []
      }
    },
    mounted() {
      this.searchOrgs(0)
    },
    watch: {
      page() {
        this.searchOrgs(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchOrgs(this.page - 1)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchOrgs(0)
            
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      }
    },
    methods: {
      searchOrgs(paginationIndex) {
        this.loading = true
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (this.search) {
          queryParams.org_name = this.search
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get('/orgs', {'params': queryParams}).then((response) => {
          console.log('OrgListing searchOrgs response', response)
          
          if (response.data.orgs.length > 0) {
            
            this.items = response.data.orgs
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      createOrg() {
        if (this.newOrgName) {
          this.loading = true
          this.showCreateOrg = false
          
          this.axios.post('/orgs', {org_name: this.newOrgName})
            .then((response) => {
              this.loading = false
              
              console.log('OrgListing createOrg response', response)
              
              if (response.data.org_guid) {
                this.$router.push('/orgs/' + response.data.org_guid)
              } else {
                this.error = true
                this.errorText = 'org_guid not returned in response'
              }
            }).catch((error) => {
              console.log('OrgListing createOrg error', error)
              
              this.error = true
              this.errorText = this.$helpers.parseError(error)
              
              this.loading = false
          })
        }
      },
      
      editItem(item) {
        console.log('OrgListing editItem', item)
        this.$router.push('/orgs/' + item.org_guid)
      },
       
      deleteItem(item) {
        console.log('OrgListing deleteItem', item)
        this.selectedOrg = item
        this.showDeleteOrg = true
      },
      
      confirmDeleteOrg() {
        if (this.deleteOrgReason) {
          this.loading = true
          this.showDeleteOrg = false
        
          this.axios.delete('/orgs/' + this.selectedOrg.org_guid, {'data': {'reason': this.deleteOrgReason}})
          .then((response) => {
            console.log('OrgListing confirmDeleteOrg response', response)
            
            this.deleteOrgReason = ''
            
            this.items = []
            this.page = 1
            this.searchOrgs(0)
            
            this.error = false
            this.errorText = ''
          }).catch((error) => {
            console.log('OrgListing confirmDeleteOrg error', error)
          
            this.error = true
            this.errorText = this.$helpers.parseError(error)
          
            this.loading = false
          })
        }
      },
      
      isLastOrganizationGuid(item) {
        if (item.org_guid == localStorage.getItem('last_organization_guid')) {
          return true
        }
        return false
      },
    }
  }
</script>