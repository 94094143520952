<template>
  <div>
    <v-dialog
      v-model="showCreateContainer"
      max-width="640px"
    >
      <videon-card heading="Create Container" showClose="true" @close="showCreateContainer = false">
        <v-card-text>
          <v-form @submit.prevent="createContainer">
            <v-text-field
              v-model="createContainerName"
              label="Container Name"
              autocomplete="off"
              :rules="$validations.containerNameValidation"
              required
              outlined
            ></v-text-field>
            
            <v-select
              v-model="createContainerImageID"
              :items="images"
              itemValue="id"
              label="Container Image"
              hide-details
              outlined
            >
              <template slot="selection" slot-scope="data">
                {{ repositoryName(data.item) }}
              </template>
              <template slot="item" slot-scope="data">
                {{ repositoryName(data.item) }}
              </template>
            </v-select>
            
            
            <v-checkbox 
              v-model="createContainerRestartPolicy"
              label="Apply Restart Policy"
            ></v-checkbox>
            <div v-if="createContainerRestartPolicy">
              <v-row no-gutters>
                <v-col cols="8">
                  <v-select
                    class="ma-1"
                    v-model="createContainerRestartPolicyName"
                    :items="restartPolicyNames"
                    label="Restart Policy"
                    hide-details
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="4" v-if="createContainerRestartPolicyName != 'always'">
                  <v-text-field
                    class="ma-1"
                    v-model.number="createContainerRestartPolicyRetries"
                    label="Maximum Retry Count"
                    type="number"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            
            <div class="my-5">
              <h4 class="mb-3">Mapped Ports</h4>
              <v-row v-for="(port, index) in createContainerPorts" v-bind:key="index" no-gutters align="center">
                <v-col cols="5">
                  <v-text-field
                    class="ma-1"
                    v-model.number="port.host_port"
                    label="Host Port"
                    type="number"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    class="ma-1"
                    v-model.number="port.container_port"
                    label="Container Port"
                    type="number"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="center">
                  <v-btn
                    small
                    text
                    @click="createContainerPorts.splice(index, 1)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-right mt-3">
                <v-btn
                  x-small
                  outlined
                  @click="createContainerPorts.push({'host_port': null, 'container_port': null})"
                >
                  Add Port Mapping
                </v-btn>
              </div>
            </div>
            
            <div class="my-5">
              <h4 class="mb-3">Environment Variables</h4>
              <v-row v-for="(variable, index) in createContainerEnvVars" v-bind:key="index" no-gutters align="center">
                <v-col cols="4">
                  <v-text-field
                    class="ma-1"
                    v-model="variable.key"
                    label="Key"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="ma-1"
                    v-model="variable.value"
                    label="Value"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="center">
                  <v-btn
                    small
                    text
                    @click="createContainerEnvVars.splice(index, 1)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-right mt-3">
                <v-btn
                  x-small
                  outlined
                  @click="createContainerEnvVars.push({'key': null, 'value': null})"
                >
                  Add Variable
                </v-btn>
              </div>
            </div>
            
            <div class="my-5">
              <h4 class="mb-3">Mapped Volumes</h4>
              <v-row v-for="(volume, index) in createContainerVolumes" v-bind:key="index" no-gutters align="center">
                <v-col cols="4">
                  <v-text-field
                    class="ma-1"
                    v-model="volume.host_path"
                    label="Host Path"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="ma-1"
                    v-model="volume.container_path"
                    label="Container Path"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="volume.mode"
                    :items="volumeModes"
                    label="Access Mode"
                    hide-details
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" align="center">
                  <v-btn
                    small
                    text
                    @click="createContainerVolumes.splice(index, 1)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-right mt-3">
                <v-btn
                  x-small
                  outlined
                  @click="createContainerVolumes.push({'host_path': null, 'container_path': null, 'mode': 'ro'})"
                >
                  Add Volume
                </v-btn>
              </div>
            </div>
            
            <div class="my-5">
              <h4 class="mb-3">Mapped Devices</h4>
              <v-row v-for="(device, index) in createContainerDevices" v-bind:key="index" no-gutters align="center">
                <v-col cols="4">
                  <v-text-field
                    class="ma-1"
                    v-model="device.host_path"
                    label="Host Path"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="ma-1"
                    v-model="device.container_path"
                    label="Container Path"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="device.mode"
                    :items="deviceModes"
                    label="Access Mode"
                    hide-details
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" align="center">
                  <v-btn
                    small
                    text
                    @click="createContainerDevices.splice(index, 1)"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-right mt-3">
                <v-btn
                  x-small
                  outlined
                  @click="createContainerDevices.push({'host_path': null, 'container_path': null, 'mode': 'rwm'})"
                >
                  Add Device
                </v-btn>
              </div>
            </div>
            
            <div class="my-5">
              <h4 class="mb-3">Advanced</h4>
              <v-alert dense type="error">
                The following settings are intended for advanced use cases only, they can expose the device system to intruders if not used properly.
              </v-alert>
               <v-row no-gutters align="center">
                <v-col cols="6">
                  <v-select
                    class="ma-1"
                    v-model="createContainerNetworkMode"
                    :items="networkModes"
                    label="Network Mode"
                    hide-details
                    outlined
                  >
                  </v-select>
                </v-col>
                <v-col cols="6" v-if="isAdmin">
                  <div class='d-flex justify-center'>
                    <v-checkbox 
                      v-model="createContainerPrivileged"
                      label="Privileged Mode"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </div>
            
            
            <div class="text-right mt-5">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showCreateContainer = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createContainer"
                :disabled="!isCreateContainerValid"
              >
                Create Container
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showPullImage"
      max-width="480px"
    >
      <videon-card heading="Pull Image" showClose="true" @close="showPullImage = false">
        <v-card-text>
          <v-form @submit.prevent="pullImage">
            <v-text-field
              v-model="pullImageRepository"
              label="Image Repository"
              autocomplete="off"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="pullImageTag"
              label="Tag"
              autocomplete="off"
              required
              outlined
            ></v-text-field>
            
            <v-text-field
              v-model="pullImageUser"
              label="Repository User (optional)"
              autocomplete="off"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="pullImagePassword"
              v-if="pullImageUser"
              label="Repository Password"
              type="password"
              autocomplete="off"
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showPullImage = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="pullImage"
              >
                Pull Image
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showConfirmKillContainer"
      max-width="480px"
    >
      <videon-card heading="Kill Container" showClose="true" @close="showConfirmKillContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to kill <span class="primary--text">{{ selectedContainer.name }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showConfirmKillContainer = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmKillContainer"
            >
              Kill
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showConfirmDeleteContainer"
      max-width="480px"
    >
      <videon-card heading="Delete Container" showClose="true" @close="showConfirmDeleteContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to delete <span class="primary--text">{{ selectedContainer.name }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showConfirmDeleteContainer = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmDeleteContainer"
            >
              Delete
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showDeleteImage"
      max-width="480px"
    >
      <videon-card heading="Delete Image" showClose="true" @close="showDeleteImage = false">
        <v-card-text>
          <p class="subtitle-1 mt-3 mb-5">
            Are you sure you want to delete <span class="primary--text">{{ repositoryName(selectedImage) }}</span>?
          </p>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showDeleteImage = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="confirmDeleteImage"
            >
              Delete
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <div v-if="!isDockerSupported" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">Unsupported Cloud Agent</h2>
          <p>
            The version of the cloud agent running on this device does not support this feature, or docker is not currently licensed for this device.
          </p>
        </v-col>
      </v-row>
    </div>
    
    <v-alert 
      dense 
      outlined 
      type="error" 
      class="ma-3"
      v-if="command_status == 'failed' || command_status == 'error' || command_status == 'rejected'">
        {{ command_message }}
    </v-alert>
    
    <div v-if="!isOnline" class="ma-2">
      <v-row align="center" style="min-height: 200px;">
        <v-col cols="2" class="text-center"><v-icon size="72" color="red">warning</v-icon></v-col>
        <v-col cols="9">
          <h2 class="my-5">Device Offline</h2>
          <p>
            Container management is not available while the device is offline.
          </p>
        </v-col>
      </v-row>
    </div>
    
    <v-card flat v-if="isDockerSupported">
      <v-card-title>
        Deployed Containers
        <v-spacer/>
        <v-btn
          small
          color="primary"
          @click="showCreateContainer = true"
          tooltip="Create Container"
          :disabled="!isOnline || isProcessing"
        >
          <v-icon>mdi-plus</v-icon> Create Container
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert dense outlined type="error" v-if="containerError">
          {{ containerErrorText }}
        </v-alert>
        
        <v-data-table
          :headers="containerHeaders"
          :items="containers"
          hide-default-footer
          disable-pagination
          :loading="(containersLoading && !noContainers) || loading"
          :items-per-page="200"
        >
          <template v-slot:item.id="{ item }">
            <div class="text-truncate" style="max-width: 110px;">
               {{ item.id }}
            </div>
          </template>
          <template v-slot:item.image="{ item }">
            <span v-if="containerImageName(item)">{{ containerImageName(item) }}</span>
            <div v-else class="text-truncate text-center" style="max-width: 200px;">
               {{ item.image }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div v-if="actionedContainer.id == item.id">
              <v-progress-linear
                color="black"
                indeterminate
                buffer-value="0"
              ></v-progress-linear>
            </div>
            <div v-else class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="startStopContainer(item)"
                    :disabled="item.status == 'paused' || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon v-if="startStopAction(item) == 'start'">mdi-play</v-icon>
                    <v-icon v-else>mdi-stop</v-icon>
                  </v-btn>
                </template>
                <span>Stop/Start Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="pauseUnpauseContainer(item)"
                    :disabled="pauseUnpauseAction(item) == 'disabled' || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon v-if="pauseUnpauseAction(item) == 'running'">mdi-pause</v-icon>
                    <v-icon v-if="pauseUnpauseAction(item) == 'unpause'">mdi-play-pause</v-icon>
                    <v-icon v-else>mdi-pause</v-icon>
                  </v-btn>
                </template>
                <span>Pause/Resume Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="restartContainer(item)"
                    :disabled="item.status != 'running' || !isOnline || isProcessing"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Restart Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="killContainer(item)"
                    :disabled="item.status != 'running' || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon>mdi-skull</v-icon>
                  </v-btn>
                </template>
                <span>Kill Container</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="deleteContainer(item)"
                    :disabled="(item.status != 'exited' && item.status != 'created') || !isOnline || isProcessing || isProtectedContainer(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Container</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card flat v-if="isDockerSupported" class="mt-10">
      <v-card-title>
        Available Images
        <v-spacer/>
        <v-btn
          small
          color="accent"
          @click="showPullImage = true"
          tooltip="Pull Image"
          :disabled="!isOnline || isProcessing"
        >
          <v-icon>mdi-plus</v-icon> Pull Image
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="imageHeaders"
          :items="images"
          :items-per-page="50"
          :loading="imagesLoading"
        >
          <template v-slot:item.tags="{ item }">
            <div v-if="item.tags && item.tags[0]">
               {{ repositoryName(item) }}
            </div>
            <div v-else class="text--disabled">
              &lt;none&gt;
            </div>
          </template>
          <template v-slot:item.id="{ item }">
            <div class="text-truncate" style="max-width: 110px;">
               {{ item.id }}
            </div>
          </template>
          <template v-slot:item.created="{ item }">
              {{ item.created | moment("YYYY/MM/DD hh:mm:ss a") }}
          </template>
          <template v-slot:item.size="{ item }">
              {{ $helpers.byteFormat(item.size) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="rePullImage(item)"
                    :disabled="!isOnline || isProcessing"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Pull Image</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small
                    @click="deleteImage(item)"
                    :disabled="!isOnline || isProcessing || isProtectedImage(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Image</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceContainers',
    
    props: ['device_guid', 'device'],
        
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b34'),
        
        commandGUID: false,
        commandResponseTimer: false,
        commandResponseCheckAttempt: false,
        commandResponseCheckInterval: 5000,
        commandResponseCheckMaxAttempts: 50,
        command_status: false,
        command_message: false,
        
        loading: false,
        
        containersLoading: false,
        noContainers: false,
        imagesLoading: false,
        
        containerError: false,
        containerErrorText: '',
        
        showCreateContainer: false,
        showPullImage: false,
        
        selectedContainer: false,
        actionedContainer: false,
        
        showConfirmKillContainer: false,
        showConfirmDeleteContainer: false,
        
        selectedImage: false,
        showDeleteImage: false,
        
        protectedContainerNames: [
          'videon-fans',
          'videon-cloud',
          'videon-api-app',
          'videon-api-proxy'
        ],
        
        protectedImageNames: [
          'spyro-lcd-menu:stable-latest',
          'spyro-daemon:stable-latest',
          'spyro-xml:stable-latest',
          'spyro-api-app:stable-latest',
          'spyro-cloud:stable-latest',
          'spyro-api-proxy:stable-latest',
          'spyro-fans:stable-latest'
        ],
        
        containerHeaders: [
          { text: 'Container ID', align: 'start', value: 'id', sortable: false },
          { text: 'Name', align: 'start', value: 'name', sortable: false },
          { text: 'Image', align: 'start', value: 'image', sortable: false },
          { text: 'Status', align: 'center', value: 'status', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false, width: '300px' },
        ],
        
        imageHeaders: [
          { text: 'Image ID', align: 'start', value: 'id', sortable: false },
          { text: 'Image', align: 'start', value: 'tags', sortable: false },
          { text: 'Created', align: 'center', value: 'created', sortable: false },
          { text: 'Size', align: 'center', value: 'size', sortable: false },
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
        
        restartPolicyNames: [
          'on-failure',
          'always'
        ],
        
        volumeModes: [
          'rw',
          'ro'
        ],
        
        deviceModes: [
          'r',
          'rw',
          'rwm'
        ],
        
        networkModes: [
          'bridge',
          'host',
          'container',
          'none',
        ],
        
        containers: [],
        images: [],
        
        createContainerName: '',
        createContainerImageID: '',
        
        createContainerRestartPolicy: false,
        createContainerRestartPolicyName: 'always',
        createContainerRestartPolicyRetries: 5,
        
        createContainerEnvVars: [],
        createContainerVolumes: [],
        createContainerPorts: [],
        createContainerDevices: [],
        createContainerPrivileged: false,
        createContainerNetworkMode: 'bridge',
        
        pullImageRepository: '',
        pullImageTag: 'latest',
        pullImageUser: '',
        pullImagePassword: '',
        
        
        refreshTimer: false,
        refreshInterval: 15000
      }
    },
    watch: {
      
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isAdmin']),
      
      isDockerSupported() {
        if (this.device.product_name == 'edgecaster_max') {
          return true
        } else if (this.device.cloud_version.dev || this.$helpers.versionCheck(this.minimumAgentVersion, this.device.cloud_version)) {
          var entitlements = this.device.entitlements()
          if (entitlements['services.docker.enabled'] == true) {
            return true
          }
        }
        return false
      },
      
      isProcessing() {
        if (this.commandGUID) {
          return true
        }
        return false
      },
      
      isCreateContainerValid() {
        if (this.$validations.isValidContainerName(this.createContainerName)) {
          return true
        }
        
        return false
      },
      
      isOnline() {
        return this.device.isOnline()
      }
    },
    
    mounted() {
      this.fetchDockerImages(true)
      this.fetchDockerContainers(true)
    },
    
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
      if (this.commandResponseTimer) {
        clearTimeout(this.commandResponseTimer)
        this.commandResponseTimer = false
      }
    },
    
    methods: {
      repositoryName(item) {
        if (item && item.tags && item.tags[0]) {
          var itemName = item.tags[0]
          
          if (itemName.includes('ecr')) {
            var splitName = itemName.split('/')
            return splitName[splitName.length - 1]
          }
          
          return itemName
        }
        return '<none>'
      },
      
      containerImageName(item) {
        var image = this.images.find(x => x.id === item.image)
        if (image) {
          return this.repositoryName(image)
        }
        return false
      },
      
      isProtectedContainer(item) {
        if (item.name && this.protectedContainerNames.includes(item.name)) {
          return true
        }
        return false
      },
      
      isProtectedImage(item) {
        if (this.protectedImageNames.includes(this.repositoryName(item))) {
          return true
        }
        return false
      },
      
      fetchDockerContainers(force = false) {
        if (this.isDockerSupported && (force || document.hasFocus())) {
          this.error = false
          this.errorText = false
          this.containersLoading = true
          
          this.axios.get('devices/' + this.device_guid + '/docker/containers').then((response) => {
            console.log('DeviceContainers fetchDockerContainers ', response)
          
            this.containers = response.data.containers
            
            if (this.containers.length == 0) {
              this.noContainers = true
            }
            
            this.actionedContainer = false
            this.containersLoading = false
          }).catch((error) => {
            this.containerError = true
            this.containerErrorText = this.$helpers.parseError(error)
            this.containersLoading = false
          })
        }
        
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            if (this.refreshTimer) {
              this.fetchDockerContainers()
              this.fetchDockerImages()
            }
            
          }, this.refreshInterval)
        }
      },
      
      fetchDockerImages(force = false) {
        if (this.isDockerSupported && (force || document.hasFocus())) {
          this.imagesLoading = true
          
          this.axios.get('devices/' + this.device_guid + '/docker/images').then((response) => {
            console.log('DeviceContainers fetchDockerImages ', response)
            
            this.images = response.data.images
            
            this.imagesLoading = false
          }).catch((error) => {
            this.containerError = true
            this.containerErrorText = this.$helpers.parseError(error)
            this.imagesLoading = false
          })
        }
      },
      
      sendDockerAction(item, action) {
        this.error = false
        this.errorText = false
        
        if (!item.id) {
          return
        }
        
        this.actionedContainer = item
        this.containersLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.loading = true
        
        this.axios.put('devices/' + this.device_guid + '/docker/containers/' + item.id, {'action': action}).then((response) => {
          console.log('DeviceContainers sendDockerAction ', response)
          
          this.actionedContainer = item
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.containerError = true
          this.containerErrorText = this.$helpers.parseError(error)
          
          this.loading = false
          this.actionedContainer = false
        })
      },
      
      checkDockerCommand() {
        if (!this.commandGUID) {
          return
        }
        
        this.axios.get('/devices/' + this.device_guid + '/commands/' + this.commandGUID)
        .then((response) => {
          console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand response', response)
          this.last_api_request_id = response.data.api_request_id
          
          if (response.data.command.finished === true) {
            console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand finished', response.data.command)
            this.commandGUID = false
            
            if (response.data.command.response && response.data.command.response.result) {
              var result = response.data.command.response.result
              console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand result', result)
              
              this.command_status = result.state.toLowerCase()
              this.command_message = result.message
              
              console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand ' + this.command_status)
              
              this.loading = false
              this.actionedContainer = false
              
              setTimeout(() => {
                // update container state
                this.fetchDockerContainers(true)
                this.fetchDockerImages(true)
              }, 5000)
            }
          } else {
            // poll until we get updated data, or we timeout...
            if (this.commandResponseCheckAttempt < this.commandResponseCheckMaxAttempts) {
              this.commandResponseTimer = setTimeout(() => {
                this.commandResponseCheckAttempt++
                this.checkDockerCommand(this.commandGUID)
              }, this.commandResponseCheckInterval)
            } else {
              console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand did not complete')
              
              this.command_status = 'error'
              this.command_message = 'No command response in ' + ((this.commandResponseCheckAttempt * this.commandResponseCheckInterval) / 1000) + ' seconds...'
              
              this.commandGUID = false
              
              this.fetchDockerContainers(true)
              this.fetchDockerImages(true)
            }
          }
        }).catch((error) => {
          console.log('DeviceContainers ' + this.device_guid + ' checkDockerCommand error', this.$helpers.parseError(error))
        }) 
      },
      
      createContainer() {
        this.error = false
        this.errorText = false
        
        // ensure the 2 required items are valid
        if (!this.createContainerImageID || !this.createContainerName) {
          return
        }
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.showCreateContainer = false
        
        this.containersLoading = true
        this.loading = true
        this.noContainers = false
        
        var postBody = {'name': this.createContainerName, 'image_id': this.createContainerImageID}
        
        // lets build our dynamic vars
        if (this.createContainerRestartPolicy) {
          if (this.createContainerRestartPolicyName == 'always') {
            this.createContainerRestartPolicyRetries = 0
          }
          postBody.restart_policy = {'name': this.createContainerRestartPolicyName, 'MaximumRetryCount': this.createContainerRestartPolicyRetries}
        }
        
        var validPorts = []
        for (var ports of this.createContainerPorts) {
          if (ports.host_port && ports.container_port) {
            validPorts.push(ports)
          } else {
            // fail validation, dont proceed...
            return
          }
        }
        if (validPorts.length > 0) {
          postBody.ports = validPorts
        }
        
        var validEnvVars = []
        for (var envVar of this.createContainerEnvVars) {
          if (envVar.key) {
            validEnvVars.push(envVar)
          } else {
            // fail validation, dont proceed...
            return
          }
        }
        if (validEnvVars.length > 0) {
          postBody.environment = validEnvVars
        }
        
        var validVolumes = []
        for (var volume of this.createContainerVolumes) {
          if (volume.host_path && volume.container_path && volume.mode) {
            validVolumes.push(volume)
          } else {
            // fail validation, dont proceed...
            return
          }
        }
        if (validVolumes.length > 0) {
          postBody.volumes = validVolumes
        }
        
        var validDevices = []
        for (var device of this.createContainerDevices) {
          if (device.host_path && device.container_path && device.mode) {
            validDevices.push(device)
          } else {
            // fail validation, dont proceed...
            return
          }
        }
        if (validDevices.length > 0) {
          postBody.devices = validDevices
        }
        
        if (this.createContainerNetworkMode) {
          postBody.network_mode = this.createContainerNetworkMode
        }
        
        if (this.createContainerPrivileged) {
          postBody.privileged = this.createContainerPrivileged
        }
        
        // passes validation, lets hide the dialog...
        console.log('DeviceContainers createContainer', postBody)
        
        this.axios.post('devices/' + this.device_guid + '/docker/containers', postBody).then((response) => {
          console.log('DeviceContainers createContainer response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.containerError = true
          this.containerErrorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      confirmDeleteContainer() {
        this.loading = true
        this.actionedContainer = this.selectedContainer
        this.showConfirmDeleteContainer = false 
        
        this.containersLoading = true
        this.loading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        this.axios.delete('devices/' + this.device_guid + '/docker/containers/' + this.selectedContainer.id, {'data': {'reason': ''}}).then((response) => {
          console.log('DeviceContainers deleteContainer response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.containerError = true
          this.containerErrorText = this.$helpers.parseError(error)
          this.loading = false
          this.actionedContainer = false
        })
      },
      
      
      
      pullImage() {
        if (!this.pullImageRepository || !this.pullImageTag) {
          return
        }
        
        console.log('DeviceContainers pullImage', this.pullImageRepository, this.pullImageTag)
        this.showPullImage = false
        this.imagesLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        var postBody = {'repository': this.pullImageRepository, 'tag': this.pullImageTag}
        
        if (this.pullImageUser && this.pullImagePassword) {
          postBody.username = this.pullImageUser
          postBody.password = this.pullImagePassword
        } else if (this.pullImageRepository.includes('ecr')) {
          postBody.videon_ecr = true
        }
        
        this.axios.post('devices/' + this.device_guid + '/docker/images', postBody).then((response) => {
          console.log('DeviceContainers pullImage response', response)
          
          this.command_status = 'accepted'
          this.command_message = 'Command Accepted'
          this.commandGUID = response.data.command_guid
        
          if (this.commandGUID) {
            this.commandResponseCheckAttempt = 0
            this.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.checkDockerCommand(this.commandGUID)
            }, this.commandResponseCheckInterval)
          }
        }).catch((error) => {
          this.containerError = true
          this.containerErrorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      rePullImage(item) {
        console.log('DeviceContainers rePullImage', item)
        
        if (item && item.tags && item.tags[0]) {
          var splitItem = item.tags[0].split(':')
          var itemName = splitItem[0]
          var itemTag = splitItem[1]
          
          this.pullImageRepository = itemName
          this.pullImageTag = itemTag
          
          this.showPullImage = true
        }
      },
      
      deleteImage(item) {
        console.log('DeviceContainers deleteImage', item)
        this.selectedImage = item
        this.showDeleteImage = true
      },
      
      confirmDeleteImage() {
        console.log('DeviceContainers confirmDeleteImage', this.selectedImage)
        
        this.showDeleteImage = false
        this.imagesLoading = true
        
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
        
        if (this.selectedImage) {
          this.axios.delete('devices/' + this.device_guid + '/docker/images/' + this.selectedImage.id, {'data': {'reason': ''}}).then((response) => {
            console.log('DeviceContainers confirmDeleteImage response', response)
            
            this.command_status = 'accepted'
            this.command_message = 'Command Accepted'
            this.commandGUID = response.data.command_guid
        
            if (this.commandGUID) {
              this.commandResponseCheckAttempt = 0
              this.commandResponseTimer = setTimeout(() => {
                this.commandResponseCheckAttempt++
                this.checkDockerCommand(this.commandGUID)
              }, this.commandResponseCheckInterval)
            }
          }).catch((error) => {
            this.containerError = true
            this.containerErrorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      
      
      // button states
      startStopAction(item) {
        if (item.status == 'running') {
          return 'stop'
        }
        return 'start'
      },
      
      pauseUnpauseAction(item) {
        if (item.status == 'paused') {
          return 'unpause'
        } else if (item.status == 'running') {
          return 'pause'
        }
        return 'disabled'
      },
      
      // button actions
      startStopContainer(item) {
        console.log('DeviceContainers startStopContainer', item)
        
        if (item.status == 'running') {
          this.sendDockerAction(item, 'stop')
        }
        this.sendDockerAction(item, 'start')
      },
      
      pauseUnpauseContainer(item) {
        console.log('DeviceContainers pauseUnpauseContainer', item)
        
        if (item.status == 'paused') {
          this.sendDockerAction(item, 'unpause')
        } else if (item.status == 'running') {
          this.sendDockerAction(item, 'pause')
        }
      },
      
      killContainer(item) {
        console.log('DeviceContainers killContainer', item)
        this.selectedContainer = item
        this.showConfirmKillContainer = true
      },
      
      confirmKillContainer() {
        console.log('DeviceContainers confirmKillContainer', this.selectedContainer)
        
        this.showConfirmKillContainer = false
        this.sendDockerAction(this.selectedContainer, 'kill')
      },
      
      deleteContainer(item) {
        console.log('DeviceContainers deleteContainer', item)
        this.selectedContainer = item
        this.showConfirmDeleteContainer = true
      },
      
      restartContainer(item) {
        console.log('DeviceContainers restartContainer', item)
        this.sendDockerAction(item, 'restart')
      },
    }
  }
</script>
