<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">HTTP Pull Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" :includeSyncGroups="true" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <videon-state-tile
        heading="Local DASH"
        v-if="device.deviceIP()"
      >
        <a :href="dashURL" target="_blank">{{ dashURL }}</a>
      </videon-state-tile>
      <videon-state-tile
        heading="Local HLS"
        v-if="device.deviceIP()"
      >
        <a :href="hlsURL" target="_blank">{{ hlsURL }}</a>
      </videon-state-tile>
    </v-row>
    
    
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="config.media_container"
          :items="media_containerValues"
          label="Media Type"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="config.segment_length"
          label="Segment Length (seconds)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="config.number_of_segments"
          label="Number of Segments"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.ULL_enable"
          label="Ultra Low Latency"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.ULL_enable">
      <v-row class="mb-3">
        <v-col cols="3">
          <v-text-field
            v-model.number="config.ULL_chunk_interval"
            label="Chunk Duration"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            v-model="config.ULL_chunk_unit"
            :items="ULL_chunk_unitValues"
            label="Chunk Unit"
            @change="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="4" v-if="config.ULL_target_latency_ms">
          <v-text-field
            v-model.number="config.ULL_target_latency_ms"
            label="Target Latency (ms)"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'HTTP_PullOutputDocument',
    
    props: ['output', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        media_containerValues: [
          { 'value': 'FMP4', 'text': 'fMP4' },
          { 'value': 'TS', 'text': 'Transport Stream' },
        ],
        
        ULL_chunk_unitValues: [
          { 'value': 'FRAMES', 'text': 'Frames' },
          { 'value': 'MILLISECONDS', 'text': 'Milliseconds' },
        ],
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        config: {
          enable: false,
          
          number_of_segments: 10,
          segment_length: 2,
          
          media_container: 'FMP4',
          
          ULL_enable: false,
          ULL_chunk_unit: 'FRAMES',
          ULL_chunk_interval: 3,
          //ULL_target_latency_ms: 4000, # this property will get set if its available in the shadow
          
          sources: {
            audio: [],
            video: []
          }
        }
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      dashURL() {
        return 'http://' + this.device.deviceIP() + ':8080/live/manifest.mpd'
      },
      hlsURL() {
        return 'http://' + this.device.deviceIP() + ':8080/live/master.m3u8'
      },
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        if (this.config.enable == true) {
          if (this.config.sources && this.config.sources.video.length == 0) {
            console.log('HTTP_PullOutputDocument ' + this.device_guid + ' validation error: Video encoder must be specified', this.config)
            validationErrors = true
          }
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        console.log('HTTP_PullOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('HTTP_PullOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>