<template>
  <v-row v-if="this.status && this.status.total_devices > 0" class="ma-2">
    <videon-metric-tile :color="online.color" heading="Online Devices" icon="devices" :description="online.label" width="30%" large class="ma-1" @click="viewDevices" />
    <videon-metric-tile :color="streaming.color" heading="Streaming Devices" icon="radio_button_checked" :description="streaming.label" width="30%" large class="ma-1" @click="viewDevices" />
    <videon-metric-tile :color="alerts.color" heading="Alerts" icon="notifications_active" :description="alerts.label" width="30%" large class="ma-1" @click="viewAlerts" />
  </v-row>
</template>

<script>
  export default {
    name: 'DeviceStatusTiles',
    props: ['status', 'useAlerts'],
    computed: {
      online() {
        if (this.status && this.status.total_devices !== undefined) {
          var color = 'info' //(this.status.total_online < this.status.total_devices) ? 'red' : 'green'
          if (this.status.total_devices == 0) {
            color = 'grey'
          }
          return {'label': '' + parseInt(this.status.total_online) + '/' + parseInt(this.status.total_devices), 'color': color}
        }
        return {'label': 'Unknown', 'color': 'grey'}
      },
      streaming() {
        if (this.status && this.status.total_devices !== undefined) {
          var color = 'info' //(this.status.total_streaming < this.status.total_devices) ? 'red' : 'green'
          return {'label': '' + parseInt(this.status.total_streaming) + '/' + parseInt(this.status.total_devices), 'color': color}
        }
        return {'label': 'Unknown', 'color': 'grey'}
      },
      alerts() {
        var color = 'grey'
        
        if (this.useAlerts && this.$alerts.unresolvedAlerts().length > 0) {
          color = 'red'
          return {'label': '' + parseInt(this.$alerts.unresolvedAlerts().length), 'color': color}
        } else if (this.status && this.status.total_alerts !== undefined) {
          color = (this.status.total_alerts > 0) ? 'red' : 'info'
          return {'label': '' + parseInt(this.status.total_alerts), 'color': color}
        }
        
        return {'label': 'Unknown', 'color': color}
      }
    }, 
    
    methods: {
      viewDevices() {
        this.$router.push('/devices')
      },
      viewAlerts() {
        this.$router.push('/alerts')
      }
    }
  }
</script>